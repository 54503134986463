/* Estilos gerais do fundo */

.background-colordetail {
    background-color: var(--bg-color);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    
}

/* Estilos para a tag */
.tag {
    position: absolute;
    top: -10px; /* Ajuste conforme necessário para posicionar corretamente */
    left: 10px; /* Ajuste conforme necessário */
    background-color: var(--first-color); /* Cor de fundo da tag */
    color: white; /* Cor do texto da tag */
    padding: 0.5rem 1rem; /* Espaçamento interno da tag */
    border-radius: 4px; /* Borda arredondada */
    font-weight: 700; /* Negrito */
    font-size: 1rem; /* Tamanho da fonte */
    z-index: 10; /* Certifique-se de que a tag fique sobreposta ao conteúdo */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Sombra para dar destaque */
}

/* Estilos para o card de detalhes do produto */
.product-detail {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    margin: 2rem;
    width: 90%;
    max-width: 1200px;
    box-sizing: border-box;
    height: 95vh;
}

/* Estilos da imagem do produto */
.product-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 50%;
}

.product-image img {
    width: 100%; /* Ajuste para garantir que a imagem ocupe a largura total */
    height: auto; /* Garantir que a altura seja proporcional */
    border-radius: 8px;
    object-fit: cover;
}

/* Estilos das informações do produto */
.product-info {
    flex: 2;
    padding: 2rem;
    text-align: left;
    box-sizing: border-box;
}

.product-title {
    font-size: 2.5rem;
    font-weight: 700;
    color: #333;
    margin-bottom: 1rem;
}

.product-price {
    font-size: 3rem;
    font-weight: 800;
    color: #333;
    margin-bottom: 0.5rem;
}

.product-discount,
.product-after,
.product-access,
.product-description {
    font-size: 1.5rem;
    color: #777;
    margin-bottom: 1rem;
}

/* Estilos da lista de características */
.product-features {
    list-style: none;
    padding: 0;
    margin-bottom: 2rem;
}

.product-features li {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
}

.product-features li::before {
    content: '✔️';
    margin-right: 0.5rem;
    color: #0056b3;
}

/* Estilos para botões principais */
.primary-btn {
    background-color: var(--first-color);
    color: #fff;
    border: none;
    transition: background-color 0.3s;
}

.primary-btn:hover {
    background-color: var(--first-color);
}

/* Estilos para botões secundários */
.secondary-btn {
    background-color: #6c757d;
    color: #fff;
    border: none;
    transition: background-color 0.3s;
}

.secondary-btn:hover {
    background-color: #5a6268;
}

/* Estilos do modal */
.Modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
    max-width: 90%;
    width: 560px;
    outline: none;
    z-index: 10000;
}

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 9999; /* Alto valor para garantir que o overlay fique no topo */
}

/* Container do vídeo */
.video-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* Estilos responsivos para telas menores */
@media (max-width: 968px) {
    .product-detail {
        flex-direction: column;
        padding-top: 2rem;
        margin-top: 2rem;
        width: 100%;
        height: auto;
    }

    .product-image {
        width: 90%;
        max-width: 100%;
        margin-bottom: 1rem;
    }

    .product-info {
        padding: 1rem;
        text-align: center;
    }

    .product-title,
    .product-price,
    .product-discount,
    .product-after,
    .product-access,
    .product-description,
    .product-features li,
    .btn {
        font-size: 1.2rem;
    }

    .product-title {
        font-size: 2rem;
    }

    .product-price {
        font-size: 2.5rem;
    }
    .background-colordetail {
        background-color: var(--bg-color);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100vw;
        height: 200vh;   
    }


    .full-background {
        background-color: var(--bg-color);
        width: 100vw;
        height: 150svh;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: auto; /* Permitir rolagem se necessário */
    }
}

/* Estilos responsivos para dispositivos móveis (telas muito pequenas) */
@media (max-width: 480px) {
    .product-detail {
        padding: 1rem; /* Aumentar o padding para dispositivos móveis */
        margin: 1rem; /* Adicionar um pequeno espaçamento */
        width: 95%; /* Ajustar a largura para caber na tela */
        text-align: center; /* Centralizar o texto para uma melhor leitura */
        height: auto;
    }
    .background-colordetail {
        background-color: var(--bg-color);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100vw;
        height: 200vh;   
    }

    
    .product-info {
        padding: 0.5rem;
    }

    .product-title {
        font-size: 1.5rem; /* Reduzir o tamanho da fonte para caber melhor */
        font-weight: 700;
        color: #333;
        margin-bottom: 0.5rem;
    }

    .product-price {
        font-size: 1.8rem; /* Reduzir o tamanho da fonte do preço */
        font-weight: 800;
        margin-bottom: 0.5rem;
    }

    .product-discount,
    .product-after,
    .product-access,
    .product-description {
        font-size: 1rem; /* Reduzir o tamanho da fonte */
        color: #777;
        margin-bottom: 1rem;
    }

    .product-features {
        list-style: none;
        padding: 0;
        margin-bottom: 2rem;
        text-align: left; /* Deixar as listas alinhadas à esquerda */
    }

    .product-features li {
        font-size: 1rem; /* Reduzir o tamanho da fonte */
        color: #333;
        margin-bottom: 0.5rem;
        display: flex;
        align-items: center;
    }

    .product-features li::before {
        content: '✔️';
        margin-right: 0.5rem;
        color: #0056b3;
    }

    .primary-btn {
        padding: 10px 20px;
        font-size: 0.9rem; /* Ajustar o tamanho do botão */
        margin-top: 10px;
        width: 100%; /* O botão ocupará toda a largura da tela */
    }
}
