@tailwind base;
@tailwind components;
@tailwind utilities;

.dark-theme{
  --text-color: white;
  --bg-color: rgb(53, 32, 32);
}

.light-theme{
  --text-color: black;
  --bg-color: #fffdd0;
}

:root{
  --first-color: hsl(271,76%, 53%);
}
.color{
  color: var(--first-color);
}
.tag {
  position: absolute;
  top: -10px; /* Ajuste conforme necessário para posicionar corretamente */
  left: 10px; /* Ajuste conforme necessário */
  background-color: var(--first-color); /* Cor de fundo da tag */
  color: white; /* Cor do texto da tag */
  padding: 0.5rem 1rem; /* Espaçamento interno da tag */
  border-radius: 4px; /* Borda arredondada */
  font-weight: 700; /* Negrito */
  font-size: 1rem; /* Tamanho da fonte */
  z-index: 10; /* Certifique-se de que a tag fique sobreposta ao conteúdo */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Sombra para dar destaque */
}

.text-color{
  color: var(--text-color);
}

.placeholder\:text-color::placeholder {
  color: var(--text-color) ;
}



.bg{
  background-color: var(--bg-color);
}

.focus\:border-color:focus {
  --tw-border-opacity: 1;
  border-color: var(--first-color);
}

.border-color {
  border-color: var(--first-color);
}

.btn {
  position: relative;
  display: inline-block;
  padding: 12px 20px;
  font-size: 18px;
  color: #fff;
  background-color: var(--first-color);
  border: none;
  border-radius: 25px;
  overflow: hidden;
  z-index: 1;
  transition: transform 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55), box-shadow 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.btn::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg) scale(0);
  width: 150%;
  height: 150%;
  background-color: rgba(255, 255, 255, 0.2);
  opacity: 0;
  z-index: -1;
  transition: transform 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55), opacity 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.btn:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.btn:hover::before {
  transform: translate(-50%, -50%) rotate(45deg) scale(1);
  opacity: 1;
}

.btn_cv{
  padding: 10px 20px;
  width: 300px;
  letter-spacing: 1px;
  font-size: 18px;
  color: white;
  border: none;
  outline:none;
  border-radius: 25px;
  background-color: var(--first-color);
  box-shadow: 0 5px 10px var(--first-color);
  cursor: pointer;
  transition: all 0.4s case;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.btn_cv:active{
  transform: scale(0.9);
  box-shadow: 0 5px 10px rgba(252,66,123,0.5) ;
}

.style__switcher{
  position: fixed;
  left:0;
  top:10%;
  background-color: #ffffff;
  width: 220px;
  padding: 15px;
  box-shadow: 0 0 4px hsl(0, 0%, 80%);
  z-index:10;
  transform: translateX(-100%);
  transition: all 0.3s ease;
}

.show-switcher{
  transform: translateX(0);
}

.style__switcher,
.style__switcher-toggler,
.language__toggler,
.theme__toggler{
  border-radius: 0 5px 5px 0;
}

.style__switcher-items{
  display: grid;
  grid-template-columns: repeat(4,1fr);
  column-gap: 4px;
  row-gap: 8px;
  
}

.style__switcher-title,
.style__switcher-toggler,
.theme__toggler,
.language__toggler{
  color: hsl(0, 0%, 40%)
}

.style__switcher-title{
  font-size: 22px;
  font-weight: bold;
  padding-bottom: 5px;
  margin-bottom: 15px;
}
.style__switcher-toggler-intern{
  background-color: var(--first-color);
  height:  50px;
  width: 50px;
  font-weight: bold;
  cursor: pointer;
  padding-bottom: 5px;
  box-shadow: 0 0 2px hsl(0, 0%, 80%);
}

.theme__img{
  width: 40px;
  cursor: pointer;
}

.style__switcher-toggler,
.language__toggler,
.theme__toggler{
  position: absolute;
  left: 100%;
  background-color: white;
  height:  50px;
  width: 50px;
  font-weight: bold;
  cursor: pointer;
  display: grid;
  place-items: center;
  box-shadow: 0 0 2px hsl(0, 0%, 80%);
  transition: all 0.3s ease;
}

.show-switcher .style__switcher-toggler,
.show-switcher .language__toggler,
.show-switcher .theme__toggler {
  left: -100%;
}

.style__switcher-toggler{
  top:5%;
}
.theme__toggler{
  bottom:5%;
}
.language__toggler{
  bottom:39%;
}


@media screen and (max-width: 768px) {
  .style__switcher {
    margin-top: 20px;
    position: static;
    width: 100%;
    transform: none;
    justify-content: center;
  }

  .nav_menu {
    position: fixed;
    inset-block: 0;
    left: -100%;
    width: 100%; /* Ajuste a largura para telas menores */
    padding: 60px 30px;
    transition: all 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    background-color: #ead9d9;
    z-index: 50;
    overflow-y: auto; /* Adiciona rolagem automática se necessário */
  }
  .style__switcher-items{
    width: 300px; /* Aumenta o tamanho da imagem */
    height: 300px; /* Aumenta o tamanho da imagem */
    
  }

  .show-menu {
    left: 0;
  }
  
  .style__switcher-toggler {
    display: none !important;
  }

  .nav_item {
    display: flex;
    flex-direction: row;
    padding-block: 5px;
    justify-content: center; /* Centraliza os itens do menu */
  }
}

.image-containerabout {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh; /* O contêiner ocupa toda a altura da tela no mobile */
}

/* Estilo para imagens responsivas em telas pequenas */
.responsive-image {
  width: 250px;
  height: 250px;
}

/* Estilo para telas maiores (não mobile) */
@media (min-width: 768px) {
  .image-containerabout {
      flex: 1;
      background-size: contain;
      color: var(--text-color);
      border-radius: 8px;
      background-repeat: no-repeat;
      height: 640px;
      background-position: top;
      justify-content: flex-start; /* Alinhar a imagem à esquerda */
      align-items: flex-start; /* Alinhar a imagem ao topo */
  }

  .responsive-image {
      width: 500px;
      height: 500px;
  }
}

@layer base {
  body {
    @apply font-secondary text-lg leading-8 text-white;
  }
  .h2 {
    @apply font-primary text-[32px] mb-6 tracking-[10%] uppercase;
  }
  .h3 {
    @apply font-primary font-semibold text-[26px] mb-6 leading-[46px];
  }
  .btn {
    @apply rounded-full font-primary text-white font-medium;
  }
  .btn-toggler {
    @apply rounded-full text-white font-medium;
  }
  .btn-sm {
    @apply h-[48px] px-6 text-sm;
  }
  .btn-lg {
    @apply h-[56px] px-10 text-base;
  }
  .btn-link {
    @apply text-base;
  }
  .text-gradient {
    @apply bg-gradient-to-r text-transparent bg-clip-text from-[#3e764f] to-[#fffdd0] font-bold font-primary hover:from-[#3e764f] hover:to-[#3e764f];
  }
  .section {
    @apply py-8 lg:py-24 lg:h-screen flex items-center;
  }
}
