/* Estilo do header */
.header {
  position: absolute;
  top: 0;
  width: 100%;
  background-color: transparent; /* cor de fundo do header */
  color: var(--first-color); /* cor do texto do header */
  font-weight: bold;
  padding: 10px 20px; /* espaço interno do header */
  display: flex;
  justify-content: space-between; /* distribuição do espaço entre logo e navegação */
  align-items: center; /* alinhamento vertical dos itens */
  z-index: 1000; /* assegura que o header fique acima de outros elementos */
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .header {
    display: none; /* Oculta o cabeçalho em telas menores ou iguais a 768px */
  }
}
/* Estilo da logo */
.logo {
  height: 40px; /* altura da logo */
}

/* Estilo da navegação */
.navh ul {
  list-style: none;
  display: flex;
  gap: 50px; /* espaço entre os itens de navegação */
  margin: 0;
  padding: 0;
}
.navh {
  padding-right: 200px;
}

.navh a {
  color: var(--first-color); /* cor do texto dos links */
  text-decoration: none; /* remover sublinhado dos links */
  font-weight: bold; /* texto em negrito */
}

.navh a:hover {
  color: var(--text-color);
}


.nav_menu {
    position:fixed; 
    inset-block:0; 
    left:-100%; 
    width:100%; 
    padding:60px 30px;
    transition: all 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    background-color: #ead9d9;
    z-index: +50;
  }

  .hide_menu{
    left: -100%;
  }
  
  .show-menu{
    left:0;
  }

  .nav_item{
    display: flex;
    flex-direction: row;
    padding-block: 5px;
  }
  .nav_link {
    display:flex;
    width: 100%;
    flex-direction: row;
    align-items:center; 
    column-gap:24; 
    border-bottom:1px solid var(--first-color); 
  }

  .nav_icon,
  .nav_name{
    color: black;
    font-size: 27px;
  }
  
  .active-nav .nav_icon,
  .active-nav .nav_name{
    color:var(--first-color)
  }

  .nav_name{
    margin-inline: auto;
  }
  
  .nav_toggle {
    position:fixed;
    top: 30px;
    right:30px;
    height: 40px;
    width: 40px;
    border-radius: 5px;
    background-color: #ead9d9;
    padding: 12px 10px;
    z-index: 51;
  }
  
  .nav_toggle span {
      display:block;
      width: 20px;
      height: 2px;
      border-radius: 2px;
      margin-bottom: 5px;
      background-color: var(--first-color);
  }
  
  .animate-toggle span:first-child {
    transform: rotate(45deg) translate(6px,4px);
  }
  
  .animate-toggle span:nth-child(2) {
    display: none;
  }
  
  .animate-toggle span:last-child {
    transform: rotate(-45deg) translate(1px,1px);
  }
  
  @media screen and (min-width: 1024px) {

    .nav_list {
        width: 120px;
        backdrop-filter: blur(2px);
        max-height: 460px;
        padding-left: 15px;
        color: rgba(255, 255, 255, 0.5);
        display: flex;
        flex-direction: column;
      }

      .nav_menu {
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        height: 100%;
        bottom: 10;
        display: flex;
        align-items: center;
        all:unset;
      }

      .nav_link {
        padding: 0;
        cursor: pointer;
        background-color: rgb(233, 233, 233);
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border-bottom: none;
        position: relative;
        margin: 10px;
      }

      .nav_item {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .nav{
        position: fixed;
        right: 2px;
        top: 1px;
        height: 100%;
        z-index: 50;
      }
      .nav_toggle{
        display: none;
      }
      .nav_icon{
        color: #2d342f;
        font-size: 25px;
        margin-inline: auto;
      }
    
      .nav_name{
        position:absolute;
        top: 0;
        right: 0;
        background-color: var(--first-color);
        height: 100%;
        line-height: 50px;
        padding-inline: 30px 25px;
        border-radius: 30px;
        color: white;
        opacity: 0;
        z-index: -1;
        visibility: hidden;
        transition: all 0.3s ease;
      }

      .nav_link:hover .nav_name{
        right: 27px;
        color: white;
        opacity: 1;
        visibility: visible;
        border-radius: 30px 0 0 30px;
      }
        
    .active-nav .nav_icon,
    .active-nav .nav_name{
            color:rgb(208, 8, 138)
        }

    .nav_link:hover,
    .active-nav{
        background-color: var(--first-color);
    }

    .nav_link:hover .nav_icon,
    .active-nav .nav_icon{
        color: white;
    }
}
  