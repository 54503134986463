.pricing-card {
  position: relative; /* Necessário para o posicionamento da tag */
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  flex: 1 1 calc(30.333% - 2rem); /* Para garantir que os cartões fiquem lado a lado em telas grandes */
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 1rem; /* Adiciona espaçamento entre os cartões */
  max-width: 400px; /* Largura máxima para os cartões */
}

.tag {
  position: absolute;
  top: -10px; /* Ajuste conforme necessário */
  left: 10px; /* Ajuste conforme necessário */
  background-color: var(--first-color); /* Cor de fundo da tag */
  color: white; /* Cor do texto da tag */
  padding: 0.5rem 1rem; /* Espaçamento interno da tag */
  border-radius: 4px; /* Borda arredondada */
  font-weight: 700; /* Negrito */
  font-size: 1rem; /* Tamanho da fonte */
  z-index: 10; /* Certifique-se de que a tag fique sobreposta ao conteúdo */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Sombra para dar destaque */
}

.pricing-title {padding-top: 10px;
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--first-color);
  margin-bottom: 1rem;
}

.pricing-price {
  font-size: 2.5rem;
  font-weight: 700;
  color: #333;
  margin-bottom: 0.5rem;
}

.pricing-discount,
.pricing-after,
.pricing-access {
  font-size: 0.875rem;
  color: #777;
  margin-bottom: 1rem;
}

.pricing-features {
  list-style: none;
  padding: 0;
  margin-bottom: 2rem;
}

.pricing-features li {
  font-size: 0.875rem;
  color: #333;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
}

.pricing-features li::before {
  content: '✔️';
  margin-right: 0.5rem;
  color: #0056b3;
}

.btn {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 4px;
  margin-bottom: 0.5rem;
  text-decoration: none;
  cursor: pointer;
}

.primary-btn {
  background-color: var(--color);
  color: #fff;
  border: none;
  transition: background-color 0.3s;
}

.primary-btn:hover {
  background-color: #004494;
}

.secondary-btn {
  background-color: #7d7dff;
  color: #fff;
  border: none;
  transition: background-color 0.3s;
}

.secondary-btn:hover {
  background-color: #6b6bff;
}
