.section {
    padding: 2rem 0;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .pricing-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Para centralizar os cartões */
    gap: 2rem; /* Espaçamento entre os cartões */
  }
  